
import { defineComponent, ref, computed, toRefs, onBeforeUpdate, reactive } from "vue";
import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
import Remarks from './Remarks.vue'

export default defineComponent({
  components: {
    Remarks
  },
  props: {
    visible_history: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Form",
    },
    data: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const form = ref(props.data);
    const form_fields = reactive({
      id: "",
      deliveries_remark:"",
      sales_remark:"",
      other_remark:"",
      closing_stock_remark:"",
    });
    const visible_history = ref(props.visible_history);
    const visible_remarks = ref(false);
    const month = ref<Moment>(moment(new Date(), 'YYYY-MM'));
    const fields = ref([
      {
        title: "Site Name",
        dataIndex: "site.site_name",
      },
      {
        title: "Tank Name",
        dataIndex: "site_product.tank.name",
      },
      {
        title: "Product Name",
        dataIndex: "site_product.product.product_name",
      },
      {
        title: "Date",
        dataIndex: "inventory_date",
      },
      {
        title: "Opening Stock",
        dataIndex: "opening_stock",
      },
      {
        title: "Delivery",
        dataIndex: "deliveries",
      },
      // {
      //   title: "Deliveries Remark",
      //   dataIndex: "deliveries_remark",
      // },
      {
        title: "Adjustment",
        dataIndex: "adjustments",
      },
      // {
      //   title: "Other Remark",
      //   dataIndex: "other_remark",
      // },
      {
        title: "Sales",
        dataIndex: "sales",
      },
      // {
      //   title: "Sales Remarks",
      //   dataIndex: "sales_remark",
      // },
      {
        title: "Closing Stock",
        dataIndex: "closing_stock",
      },
      // {
      //   title: "Closing Remarks",
      //   dataIndex: "closing_stock",
      // },
      {
        title: "Book Stock",
        dataIndex: "book_stock",
      },
      {
        title: "Variance",
        dataIndex: "variance",
      },
      {
        title: "Action",
        dataIndex: "inventory",
        slots: { customRender: "inventory" },
        align: 'center'
      },
    ]);

    const getData = (record: any) => {
      let selected_month = moment(month.value).format(
        "YYYY-MM"
      );
      store
        .dispatch("RetailerInventories/history",{selected_month,record});
    };

    const cancel = (record: any) => {
      visible_history.value = false;
    };

    const showRemarks = (record: any) => {
      const { deliveries_remark, sales_remark, other_remark, closing_stock_remark} = toRefs(form_fields);
      deliveries_remark.value = record.deliveries_remark;
      sales_remark.value = record.sales_remark;
      other_remark.value = record.other_remark;
      closing_stock_remark.value = record.closing_stock_remark;
      visible_remarks.value = true;
    };

    onBeforeUpdate(() => {
     let record = items.value;
     if(record.data){
       record.data.length = 0;
     }
    
    });
    
    const items = computed(() => store.getters["RetailerInventories/history_items"]);

    return {
      form,
      month,
      fields,
      items,
      visible_remarks,
      form_fields,
      getData,
      showRemarks,
      cancel
    };
  },
});
