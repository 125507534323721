import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible_remarks,
    title: _ctx.title,
    width: "800px",
    footer: null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('save', _ctx.form)), ["prevent"])),
        id: "historyForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, { label: "Delivery Remark" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: _ctx.form.deliveries_remark,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.deliveries_remark) = $event)),
                    rows: 3
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, { label: "Sales Remark" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: _ctx.form.sales_remark,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.sales_remark) = $event)),
                    rows: 3
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, { label: "Other Movements Remark" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: _ctx.form.other_remark,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.other_remark) = $event)),
                    rows: 3
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, { label: "Closing Stock Remark" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: _ctx.form.closing_stock_remark,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.closing_stock_remark) = $event)),
                    rows: 3
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}