
import { defineComponent, ref, onBeforeUpdate, computed, onMounted, inject } from "vue";
import moment, { Moment, now } from 'moment';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Form",
    },
    data: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const form = ref(props.data);
    const dateFormat = 'YYYY/MM/DD';
    const category_type = ref('');
    const adjustment = inject<any>('adjustment');

    const getAllAdjustmentCategories = () => {
      store.dispatch("Option/getAllAdjustmentCategories");
    };

    const getCategoryType = (id:any) => {
      store.dispatch("AdjustmentCategory/show",id).then((res) => (
        category_type.value = res.data.type
      ));
    };

    const all_adjustment_categories = computed(() => store.getters["Option/all_adjustment_categories"]);

    onBeforeUpdate(getAllAdjustmentCategories);
    onBeforeUpdate(() => {
       category_type.value = adjustment.category_type
    });

    return {
      form,
      dateFormat,
      moment,
      value1: ref<Moment>(moment(new Date(), dateFormat)),
      all_adjustment_categories,
      category_type,
      adjustment,
      getCategoryType,
    };
  },
});
