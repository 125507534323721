
import { defineComponent, ref, computed, onMounted, onUpdated, reactive, toRefs,provide, h, inject } from "vue";
import { useStore } from 'vuex';
import Adjustment from "./adjustment/Adjustment.vue"

export default defineComponent({
  components: {
    Adjustment
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Form",
    },
    id: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  setup(props) {
    const inventory = inject<any>('inventory');
    const adjustments = ref('');
    const form = ref<any>(props.data)
    const store = useStore();
    const visible_adj = ref(false);
    const form_action = ref('save')
    const fields = ref([
      {
        title: "Date",
        dataIndex: "created_at",
      },
      {
        title: "Category",
        dataIndex: "adjustment_category.name",
      },
      {
        title: "Type",
        dataIndex: "adjustment_category.type",
      },
      {
        title: "For Date",
        dataIndex: "for_date",
      },
      {
        title: "Value",
        dataIndex: "value",
      },
      {
        title: "Adjusted By",
        dataIndex: "adjusted_by.full_name",
      },
      {
        title: "Action",
        dataIndex: "action",
        slots: { customRender: "action" },
        align: 'center'
      },
    ]);
    const form_adjustment = reactive({
      id: "",
      value:"",
      for_date: "",
      adjustment_category_id: "",
      inventory_id:"",
      category_type:"",
      adjusted_by_id:""
    });

    provide('adjustment',form_adjustment);

    const computeBookStock = () => {
      if (!form.value.deliveries || !form.value.sales) {
        console.log('here')
        return 0
      }

      console.log('OP STOCK ' + parseFloat(form.value.opening_stock))
      console.log('DELI ' + parseFloat(form.value.deliveries))
      console.log('SALEEES ' + parseFloat(form.value.sales))
      console.log('ADJUU ' + (parseFloat(adjustments.value) || 0))
      return (parseFloat(form.value.opening_stock) + parseFloat(form.value.deliveries)) -
          parseFloat(form.value.sales) + (parseFloat(adjustments.value) || 0)
    }

    const computeVariance = () => {
      if (!form.value.closing_stock || !computed_book_stock.value) {
        return 0
      }

      return parseFloat(form.value.closing_stock) - computed_book_stock.value
    }

    const index = (payload={}) => {
      payload = {
        page: 1,
        inventory_id: props.id,
      };

      store.dispatch("InventoryAdjustment/index", payload);
    };

    const createRecord = () => {
      form_action.value = 'save'
      const { value, for_date, adjustment_category_id,category_type } = toRefs(form_adjustment);
      value.value = '';
      adjustment_category_id.value = '';
      for_date.value = '';
      category_type.value = '';
      visible_adj.value = true;
    };

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const { id, value, for_date, adjustment_category_id,category_type } = toRefs(form_adjustment);
      id.value = record.id,
      value.value = record.value;
      adjustment_category_id.value = record.adjustment_category_id;
      for_date.value = record.for_date;
      category_type.value = record.adjustment_category.type;
      visible_adj.value = true;
    };

    const handleSaving = (record: any) => {
      form_action.value == 'save' ? storeRecord(record) : updateRecord(record);
    }

    const storeRecord = (payload:any) => {
      payload.inventory_id = props.id
      store
        .dispatch("InventoryAdjustment/store", payload)
        .then(() => (
          visible_adj.value = false,
          index({ 
              page: 1,
              inventory_id: props.id
            }),
            filter()
        ));
    };

    const updateRecord = (payload:any) => {
      payload.inventory_id = props.id
      store
        .dispatch("InventoryAdjustment/update", payload)
        .then(() => (
          visible_adj.value = false,
         
          index({ 
              page: 1,
              inventory_id: props.id
            }),
            filter()
        ));
    };

    const deleteRecord = (record: any) => {
      store.dispatch('InventoryAdjustment/destroy', record.id)
       .then(() => (
          index({ 
              page: 1,
              inventory_id: props.id
            }),
            filter()
        ));

    }

    const filter = () => {
      store.dispatch('Inventories/show', props.id).then((res) => (
          adjustments.value = res.data.adjustments
      ))
      store.dispatch('Inventories/index', {page: 1, selected_site: inventory.site_id})
    }

    const items = computed(() => store.getters["InventoryAdjustment/items"]);
    const loading = computed(() => store.getters["InventoryAdjustment/status"]);
    const computed_book_stock = computed(() => computeBookStock())
    const computed_variance = computed(() => computeVariance())

    onUpdated(() => {
      filter()
      adjustments.value = inventory.adjustments
      index()
    })

    return {
      form,
      fields,
      items,
      visible_adj,
      form_adjustment,
      form_action,
      loading,
      adjustments,
      inventory,
      createRecord,
      handleSaving,
      editRecord,
      deleteRecord,
      computed_book_stock,
      computeBookStock,
      computed_variance,
      computeVariance
    };
  },
});
